import React from 'react';
import { darken } from 'polished';

import Layout from 'components/layout';
import SEO from 'components/seo';
import Section from 'components/section';
import ThumbPost from 'components/thumb-post';

import {
  Link, Text, getColor,
} from 'style-guide';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Section
      title="Blog"
    >
      <ThumbPost
        category="javascript"
        title="What is Hoisting in JavaScript?"
        paragraph="Find out what hoisting in JavaScript means, with examples of code  to help explain it all."
        date="Nov 27, 2019"
        readingTime={9}
      />
      <ThumbPost
        category="functional programming"
        title="Patterns to improve your code skills using FP"
        paragraph="Have you been using Functional Programming on your code? No! So it's the time to train your brain to think more functional"
        date="Out 22, 2019"
        readingTime={5}
      />
      <ThumbPost
        category="leadership"
        title="Habits that will increase yours changes to be a better leader"
        paragraph="Everyone have a body. You have to train your body to do what you to do, not what your body want to do"
        date="Dec 10, 2019"
        readingTime={7}
      />
      <Link
        to="/"
        color={getColor('cranberry')}
        hoverColor={getColor('cranberry', darken(0.2))}
      >
        <Text type="heading">{'See all posts >>'}</Text>
      </Link>
    </Section>
  </Layout>
);

export default IndexPage;
