import React from 'react';
import styled from 'styled-components';
import pt from 'prop-types';

import {
  Text, Title, Box, Flex, Link,
  getSpacing, getColor,
} from 'style-guide';

const Paragraph = styled(Text)`
  max-height: 44px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Article = styled.article`
  max-width: 570px;
  padding: ${getSpacing('loose')};
  margin: ${getSpacing('loose')} 0;

  &:hover {
    border-radius: 5px;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.15);
  }
`;

const ThumbPost = ({
  category, title, paragraph, date, readingTime,
}) => (
  <Article>
    <Flex alignItems="start">
      <Box>
        <Text type="subheading" color={getColor()}>{category}</Text>
        <Title.Tiny mb={getSpacing('base-tight')} mt={getSpacing('base-tight')}>
          <Link to="/" color={getColor('biscay')}>
            {title}
          </Link>
        </Title.Tiny>
        <Box mb={getSpacing('base-tight')}>
          <Paragraph type="body" color={getColor('lightGray')}>
            {paragraph}
          </Paragraph>
        </Box>
        <Box bottom="0" style={{ textAlign: 'right' }}>
          <Text type="body" color={getColor('rawSienna')}>
            {`${date} •`}
            {` ${readingTime} min reading`}
          </Text>
        </Box>
      </Box>
    </Flex>
  </Article>
);

ThumbPost.propTypes = {
  category: pt.string.isRequired,
  title: pt.string.isRequired,
  paragraph: pt.string.isRequired,
  date: pt.string.isRequired,
  readingTime: pt.number.isRequired,
};

export default ThumbPost;
